body::-webkit-scrollbar {
	width: 6px;
	background-color: #fff;
}

body::-webkit-scrollbar-thumb {
	background-color: #222;
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #fff;
}

.totalslide {
	position: absolute;
	text-align: center;
	transition: 300ms opacity;
	transform: translate3d(0, 0, 0);
	z-index: 10;
	bottom: 0px;
	font-size: 20px;
	opacity: 0.6;
}

.activeslide {
	position: absolute;
	text-align: center;
	transition: 300ms opacity;
	transform: translate3d(0, 0, 0);
	z-index: 10;
	bottom: 0;
	font-size: 20px;
	opacity: 1;
}

.swiper-pagination-progressbar {
	height: 2px;
	background: #DCD9D9 !important;
	width: 100%;

	.swiper-pagination-progressbar-fill {
		background: #4F4F4F !important;
	}
}

.swiper-button-prev::before,
.swiper-button-next::before {
	display: none !important;
}

.title-cate {
	margin-bottom: 20px;
	font-family: var(--font1);

	.cate {
		color: #B4286C;
		font-size: 28px;
		font-weight: bold;
		text-transform: uppercase;
		padding: 0px 0 0px 28px;
		background: url(images/graphics/bg-title.png) no-repeat left top 5px;
	}

	.title {
		color: #202020;
		font-weight: 600;
		font-size: 36px;
		line-height: 1;

		&.txt_48 {
			font-size: 48px;
		}

		@media (max-width:1025px) {
			font-size: 30px
		}
	}

	&.txt_36 {
		margin-bottom: 24px;

		.title {
			font-size: 36px;

			@media (max-width:900px) {
				font-size: 26px
			}
		}
	}
}

.btn-default {
	background: #202020;
	border-radius: 24px 0px;
	line-height: 48px;
	font-size: 16px;
	font-family: var(--font1);
	color: #F6F6F6;
	display: inline-block;
	padding: 0 20px;
	transition: 0.2s all;
	font-weight: bold;

	&.gray {
		background: #F7F7F7;
		color: #4F4F4F;

		&:hover {
			background: #202020;
			color: #F6F6F6;
		}
	}

	&__2 {
		font-size: 14px;
		line-height: 40px;
		padding: 0 40px;
		font-weight: bold;
	}
}

.bg-white {
	background: #FFFFFF;

	&::before {
		display: table;
		content: "";
		clear: both;
	}
}

.padding_24 {
	padding: 24px;
}

.radius_8 {
	border-radius: 8px;
}

.border {
	border: 1px solid rgba(0, 0, 0, 0.05);
}

/*breadcrumb*/
.breadcrumb{
    background: #F0F0F0;
    font-size: 14px;
    color: #757575;
    margin-bottom: 16px;
    height: 45px;
    @extend %flexbox;
    align-items: center;
    .parent{
        color: #222;
    }
    .item{
        margin-left: 10px;
        padding-left: 10px;
        position: relative;
        &:before{
            content: '';
            width: 5px;
            height: 5px;
            border-top: 1px solid #757575;
            border-right: 1px solid #757575;
            transform: rotate(45deg);
            position: absolute;
            left: -5px;
            top: 8px;
        }
    }
}
/*End breadcrumb*/

#pagination {
	text-align: right;
	margin-bottom: 20px;

	&.center {
		text-align: center;
	}

	a {
		background: #F3F3F3;
		border-radius: 10px 0px;
		color: #2B2B2B;
		font-size: 18px;
		width: 48px;
		height: 48px;
		line-height: 48px;
		display: inline-block;
		text-align: center;
		margin-left: 2px;

		&.active {
			background: #B4286C;
			color: #fff;
		}
	}

	.pa_prev {
		svg {
			transform: rotate(-90deg);
		}
	}

	.pa_next {
		svg {
			transform: rotate(-90deg);
		}
	}
}

.pagination-left #pagination {
	text-align: left
}

.pagination-center #pagination {
	text-align: center
}

.pagination-right #pagination {
	text-align: right
}

[class*='grid__'] {
	column-gap: 24px;
	row-gap: 24px;
	position: relative;

	.art_item {
		margin-bottom: 0;
	}
}

.grid {
	display: grid;
	width: 100%;

	&__1 {
		grid-template-columns: repeat(1, 1fr);
	}

	&__2 {
		grid-template-columns: repeat(2, 1fr);
	}

	&__3 {
		grid-template-columns: repeat(3, 1fr);
	}

	&__4 {
		grid-template-columns: repeat(4, 1fr);
	}

	&.grid_3 {
		grid-template-columns: repeat(3, 1fr);
		column-gap: 24px;
		row-gap: 24px;
		position: relative;

		.art_item {
			display: block;
			margin-bottom: 0;
		}

		&::before {
			display: none;
		}

		.thumb_art {
			width: 100%;
			margin-bottom: 15px;
		}

		.content {
			width: 100%;
			padding-left: 0;

			.title_news {
				margin-bottom: 0;
			}
		}
	}
}


input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
textarea,
select.form-control {
	background: #fff;
	width: 100%;
	height: 40px;
	border: 1px solid #E5E5E5;
	font-size: 16px;
	padding: 0 12px;
	outline: none;
	border-radius: 0;
	font-family: arial;
	transition-duration: 200ms;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);

	@include placeholder {
		color: #757575;
	}

	&:focus,
	&:active {
		outline: 0;
		box-shadow: none;
		border: 1px solid var(--color-2);
	}
}

.form-default {
	.form-group {
		margin-bottom: 24px;

		.lbl {
			display: block;
			margin-bottom: 10px;
		}

		.form-control {
			color: #4F4F4F;
		}
	}

	textarea.form-control {
		padding: 12px;
		height: 100px;
		resize: none;
	}

	.btn-default {
		border: 0;
		font-size: 14px;
		font-weight: bold;
		line-height: 40px;
		min-width: 95px;
	}
}

.check-list {
	padding-left: 26px;
	position: relative;
	cursor: pointer;
	display: block;
	font-weight: 400;
	margin-right: 100px;
	font-size: 16px;
	color: #202020;

	.checkmark {
		position: absolute;
		top: 2px;
		left: 0;
		width: 20px;
		height: 20px;
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		border-radius: 50%;

		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 2px;
			top: 2px;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background: #B4286C;
		}
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		display: none;
	}

	input:checked~.checkmark {
		border: 1px solid var(--colormain);
	}

	input:checked~.checkmark:after {
		display: block;
	}
}

.lazy {
	position: absolute;
	top: 0;
	left: 0;
}

.tip {
	position: absolute;
	top: -30px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	height: 24px;
	line-height: 24px;
	background: #17191A;
	color: #fff !important;
	font-size: 12px !important;
	text-decoration: none !important;
	white-space: nowrap;
	padding: 0 10px;
	border-radius: 3px;
}