/*@import "normalize.scss";*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

html,
body {
  text-rendering: optimizeSpeed;
}

body {
    color: inherit;
    font: 400 px_to_rem(14px) $fontmain;
    line-height: $lhbody;
    text-align: left;
    background: var(--bg-body);
    color: var(--color-text-1);
}

.body_mobile {
  max-width: 1020px;
  margin: 0 auto;
  overflow-x: hidden;
}

button,
.embed_video_new,
.box_img_video {
  cursor: pointer;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
}

a:focus {
  outline: 1 !important;
}

.box_category {
  margin-bottom: 28px;
  float: left;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
}


select {
  color: var(--color-text-1);
}

a {
  color: inherit;
  text-decoration: none;
  outline: 1;
    cursor: pointer;
}

a:hover {
  color: var(--colormain);
}
.section{
    width: 100%;
    float: left;
}
.container {
  max-width: $main-width + $padding*2;
  position: relative;
  margin: 0 auto;
  padding: 0 $padding;
  @include clearfix;
}
.main-content{
    width: calc(100% - 360px);
}
.sidebar{
    width: 360px;
    padding-left: 60px;
}
.title-section{
    line-height: 1.53;
    color: var(--color-text-1);
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    font-family: $font-title;
    .parent-cate{
        font-size: 30px;
        font-weight: 700;
        @extend %flexbox;
        align-items: center;
        .icon-ct{
            color: $colormain;
            //font-size: 30px;
            margin-right: 10px;
            display: flex;
            width: 30px;
            height: auto;
            img{
                width: 30px;
            }
        }
    }
    .sub-cate{
        margin-left: 30px;
        font-size: 16px;
        &.active{
            color: $colormain;
        }
    }
    &.style-center{
        .parent-cate{
            margin: auto;
            font-size: 36px;
            font-weight: 400;
        }
    }
}
.min-container {
  max-width: 1134px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  @include clearfix;
}

.min-width {
  max-width: 850px;
  margin: 0 auto;

  @media (max-width:900px) {
    max-width: 100%;
  }
}
.box-category{
    width: 100%;
    float: left;
    margin-bottom: 30px;
}

.flex {
  display: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flexbox{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
.grid{
    display: grid;
}
.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}
.width_common {
  width: 100%;
  float: left;
}

.sticky {
  position: sticky;
  top: 0
}
.top-70{
    top: 70px;
}

.text_ads {
  width: 100%;
  float: left;
  font: 400 11px arial;
  color: #a7a7a7;
  text-align: left;
  margin-bottom: 5px;
}

.center {
  text-align: center;
}

#to_top {
  background: $colormain !important;
  display: none;
  border: medium none;
  bottom: 20px;
  cursor: pointer;
  text-align: center;
  height: 40px;
  line-height: 38px;
  outline: medium none;
  padding: 0;
  position: fixed;
  right: 20px;
  width: 40px;
  z-index: 9999;
 transition: all .3s ease;
  .icon-ct {
    margin: 0;
    color: #fff;
      font-size: 20px;
  }
    &:hover{
        opacity: 1;    
    }
}

img {
  border: 0;
  font-size: 0;
  line-height: 0;
  max-width: 100%;
  vertical-align: middle;
}

ul,
li {
  list-style-type: none;
}

input,
textarea {
  font-family: arial;
  font-size: 11px;
  border: none;
  background: none;

}

input::-webkit-input-placeholder {
  color: #999999;
}

input::-moz-input-placeholder {
  color: #999999;
}

input::-ms-input-placeholder {
  color: #999999;
}

input::-o-input-placeholder {
  color: #999999;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
button{
    border: none;
    outline: none;
}
.hidden,
.grecaptcha-badge {
  display: none !important;
}

.clearfix {
  clear: both;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: "";
  clear: both;
}

.left {
  float: left;
}

.right {
  float: right;
}

.hidden320 {
  font-size: 0;
}

html {
  -webkit-text-size-adjust: none;
}

.fill-gradient {
  position: relative;

}

.icon-ct {
  height: 1em;
  width: 1em;
  vertical-align: middle;
  fill: currentColor;

  .title_news & {
    /*fill: url(#my-cool-gradient);    */
  }
}

.ic {
  width: 16px;
  height: 16px;
  fill: #757575;
  display: inline-block;
  vertical-align: middle;
}

.not-grad {
  fill: currentColor !important;
}

.comment-count,
.count_cmt {
  color: #757575;
  font: 400 12px arial !important;
  margin-left: 2px;

  svg {
    vertical-align: text-bottom;
  }
}

.ns-play {
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
  height: 35px;
  width: 35px;
  background: url(images/icons/Play.svg) no-repeat center;
  background-size: contain;
  margin: 0;

  use {
    display: none
  }
}

.no_wrap {
  white-space: nowrap;
}

.txt_666 {
  color: #666;
}

.txt_999 {
  color: #999;
}

.txt_10 {
  font-size: 10px;
}

.txt_11 {
  font-size: 11px;
}

.txt_14 {
  font-size: 14px;
}

.txt_16 {
  font-size: 16px;
}

.txt_18 {
  font-size: 18px;
}

.txt_24 {
  font-size: 24px !important;
}

.txt_vne {
  color: #9f224e;
}
$index : 0; 
@while $index <= 100
{
    .mb#{$index}{
        margin-bottom: #{$index}px !important;
    }
    
    .mt#{$index}{
        margin-top: #{$index}px !important;
    }
    
    .ml#{$index}{
        margin-left: #{$index}px !important;
    }
    
    .mr#{$index}{
        margin-right: #{$index}px !important;
    }
    
    .pt#{$index}{
        padding-top: #{$index}px !important;
    }
    
    
    .pb#{$index}{
        padding-bottom: #{$index}px !important;
    }
    
    $index : ($index + 5);
}
.error {
  color: red;
  font-size: 12px;
}

.error_mess {
  color: #9f224e;
  font-size: 13px;
  line-height: 15px;
  margin-top: 5px;
}

.bg_gray {
  background: #f8f8f6 !important;
}

.fs {
  font-size: 0
}

.thumb_big {
  position: relative;
}


.thumb_img,
.thumb {
  display: block;
  overflow: hidden;
  height: 0;
  position: relative;
  width: 100%;
    border-radius: 5px;
}
.feature{
    .thumb_img,
    .thumb{
        border-radius: 0;
    }
}
.thumb_img,
.thumb_video,
.thumb_16x9 {

  video,
  img,
  iframe {
    -o-object-position: top;
    object-position: top;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}


.thumb-video{
    padding-bottom: 56.25%;
}
.thumb_5x3,
.thumb-5x3 {
  padding-bottom: 60%;
}

.thumb_3x4 {
  padding-bottom: 133.33%;
}
.thumb_4x3{
    padding-bottom: 75%;
}
.thumb_2x3,
.thumb-2x3 {
  padding-bottom: 150%;
}

.thumb_1x1,
.thumb-1x1 {
  padding-bottom: 100%;
}

.thumb_16x9,
.thumb_video {
  padding-bottom: 56.25%;
}

.video-js video {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.thumb_video+.title_news {
  margin-top: 10px;
  font-size: px_to_rem(16px);
  text-transform: uppercase;
  line-height: 1.4;

  a {
    color: var(--colormain)
  }
}

.line_8px {
  background: #eeeeee;
  height: 8px;
  width: 100%;
  clear: both;
}

.line_4px {
  background: #eeeeee;
  height: 4px;
  width: 100%;
  clear: both;
}

.line_2px {
  background: #eeeeee;
  height: 2px;
  width: 100%;
  clear: both;
}

.line_1px {
  background: #eeeeee;
  height: 1px;
  width: 100%;
  clear: both;
}

/* ******** Browser ************* */

/*Only Safari*/
_::-webkit-full-page-media,
_:future,
:root .box_stream {
  width: calc(100% - 349px);
}

_::-webkit-full-page-media,
_:future,
:root .featured .art_topstory {
  width: 65.9%;
}

_::-webkit-full-page-media,
_:future,
:root .box_tieudiem .list_news {
  width: 845px;
}

@media (max-width: 991px) {

  _::-webkit-full-page-media,
  _:future,
  :root .box_stream {
    width: 100%;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .featured .art_topstory {
    width: 100%;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .box_tieudiem .list_news {
    width: calc(100% - 255px);
  }
}