.header{
    height: 48px;
    background: #fff;
    .container{
        height: 100%;
        display: flex;
        align-items: center;
    }
    .btn-open-close{
        width: 30px;
        height: 30px;
        @extend %flexbox;
        align-items: center;
        justify-content: center;
        margin-left: -7px;
        color: #222;
        .icon-ct{
            font-size: 16px;
        }
    }
    .logo{
        width: 106px;
    }
    .right{
        margin-left: auto;
        align-items: center;
    }
    .link-search{
        font-size: 18px;
        color: #222;
    }
}

/*left menu*/
.left-menu{
    width: 100%;
    height: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 300;
    background: #F2F2F2;
    overflow-y: auto;
    transition: all .3s ease;
    opacity: 0;
    visibility: hidden;
    .form-search{
        width: 100%;
        float: left;
        padding: 0 15px;
        margin-bottom: 14px;
        input{
            width: 100%;
            float: left;
            background: #FFFFFF;
            border-radius: 35px;
            padding-right: 40px;
        }
        .btn-search{
            background: none;
            width: 40px;
            height: 40px;
            @extend %flexbox;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 5px;
            top: 0;
        }
        .icon-ct{
            color: #4F4F4F;
            font-size: 20px;
        }
    }
    .input-search{
        width: 100%;
        float: left;
        position: relative;
    }
    .copyright{
        clear: both;
        padding: 0 15px;
        color: #222;
    }
    .wrap-link{
        width: 100%;
        float: left;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:15px;
        padding: 0 15px;
        .item-link{
            height: 40px;
            line-height: 37px;
            background: #FFFFFF;
            border: 1px solid #E4E4E4;
            border-radius: 35px;
            display: block;
            text-align: center;
            color: #222;
        }
    }
}
.header-left-menu{
    width: 100%;
    height: 48px;
    @extend %flexbox;
    align-items: center;
    background: #fff;
    padding: 0 15px;
    margin-bottom: 16px;
    .logo{
        width: 106px;
    }
    .btn-open-close{
        width: 30px;
        height: 30px;
        @extend %flexbox;
        align-items: center;
        justify-content: center;
        margin-left: -7px;
        color: #222;
    }
}
.list-left-menu{
    width: 100%;
    float: left;
    padding: 0 15px;
    .item{
        width: 100%;
        height: 40px;
        float: left;
        @extend %flexbox;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #E4E4E4;
        color: #222;
        margin-bottom: 14px;
        padding: 0 15px;
        &:not(:first-child){
            &:after{
                content: '';
                width: 6px;
                height: 6px;
                border-top: 1px solid #222;
                border-right: 1px solid #222;
                transform: rotate(45deg);
                margin-left: auto;
            }
        }
        .btn-swipe{
            width: 28px;
            height: 16px;
            padding: 2px;
            background: var(--color-1);
            border-radius: 30px;
            margin-left: auto;
            display: flex;
            align-items: center;
            position: relative;
            &:before{
                content: '';
                width: 12px;
                height: 12px;
                background: #fff;
                border-radius: 50%;
                position: absolute;
                left: 2px;
                transition: all .3s ease;
            }
        }
    }
}
.darkmode{
    .list-left-menu .item .btn-swipe{
        &:before{left: 14px;}
    }
}
.open-left-menu{
    overflow: hidden;
    .left-menu{
        opacity: 1;
        visibility: visible;
        left: 0;
    }
}
/*End left menu*/

/*main nav*/
.main-nav{
    width: 100%;
    float: left;
    background: var(--colormain);
    z-index: 300;
}
.list-nav{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    overflow-x: auto;
    .item-nav{
        height: 48px;
        font-family: $font-title;
        flex-shrink: 0;
        @extend %flexbox;
        align-items: center;
        &.active{
            a{
                background: #D55509;
                border-radius: 35px;
                color: #fff;
            }
        }
        & > a{
            display: inline-block;
            padding: 3px 12px;
            transition: all .3s ease;
            color: #fff;
        }
        &.home{
            a{
                padding-left: 0;
                padding-right: 8px;
            }
        }
        .icon-ct{
            font-size: 21px;
        }
    }
    .swipe-mode{
        margin-left: auto;
        a{
            display: flex;
            align-items: center;
        }
        &:hover{
            a{
                color: #fff;
            }
        }
        .btn-swipe{
            width: 28px;
            height: 16px;
            padding: 2px;
            background: var(--color-1);
            border-radius: 30px;
            margin-left: 5px;
            display: flex;
            align-items: center;
            position: relative;
            &:before{
                content: '';
                width: 12px;
                height: 12px;
                background: #fff;
                border-radius: 50%;
                position: absolute;
                left: 2px;
                transition: all .3s ease;
            }
        }
    }
}
/*End main nav*/

.dark-mode{
    .list-nav .swipe-mode .btn-swipe{
        &:before{left: 14px;}
    }
}